/* 

body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--blue);
    --accent: var(--white);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--black);
    padding-bottom: 100px;
  }
   */
  /* #root {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
  } */
  
  /* * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
  
  h1,
  h2,
  h3 {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  h1 {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
  }
  
  h2 {
    font-weight: 400;
    margin: 50px 0 10px;
  }
  
  p {
    margin: 0 0 30px 0;
    font-size: 18px;
  } */
/*   
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(
      rgba(0, 0, 0, 0) 1px,
      var(--background) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
  }
  
  footer::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent);
    opacity: 0.2;
  }
  
  footer svg {
    margin-right: 20px;
  }
  
  footer a {
    text-decoration: none;
    color: var(--accent);
  } */
   
  .linkItems{
    text-decoration: none;
    color: initial;
  }
  .carouselCover{
    position: relative;
    padding-left: 30px;
    margin-top: 30px;
    /* background-color: green; */
  }
  .carouselCover img{
    object-fit: cover;
  }

  .Prewording{
    overflow: hidden;
    text-align: center;
    width: 200px;
    /* padding-left: 30px; */
  }
  .Prewording h2{
    padding: 10px 15px;
    background-color: #000;
    color: white;
    border-radius: 3px;
    /* padding-left: 30px; */
  }

  .carouselImg{
    height: 100%;
    width: 100%;
    overflow: hidden;

  }
  
  .carouselCover code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
  }
  
 .carouselCover #progress {
    position: fixed;
    top: 20px;
    left: 20px;
    transform: rotate(-90deg);
  }
  
  circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
  }
  
  .bg {
    stroke: var(--accent);
    opacity: 0.3;
  }
  
  #progress .indicator {
    stroke: var(--accent);
  }

   .carouselList{
    /* background-color: red; */
    height: 450px;
  } 
   
.carouselCover ul {
    display: flex;
    list-style: none;
    height: 450px;
    /* background-color: green; */
    overflow-x: scroll;
    padding: 20px 0;
    flex: 0 0 600px;
    margin: 0 auto;
    /* background-color: red; */
  }
  
  ::-webkit-scrollbar {
    height: 10px;
    width: 5px;
    background: #fff3;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-thumb {
    /* background: var(--accent); */
    /* background-color: aqua; */
    background-color: black;
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }
  
.carouselCover li {
    flex: 0 0 370px;
    /* background: var(--accent); */
    margin: 0 20px 0 0;
    user-select: none;
    /* background-color: aqua; */
    overflow: hidden;
    cursor: pointer;
  }
  
 .carouselCover li:last-of-type {
    margin: 0;
  }


  /* CARD CSS */
  

  .ListingHeadingCover{

    /* padding-left: 30px; */
    padding-bottom: 30px;
    width: 100%;
    text-align: left;
    color: rgb(58, 140, 140);

    

}

.ListingContainer {
    position: relative;
    padding: 30px;
}

.ListingDisplayCover {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.ListingItemCard {
    width: 100%;
    height: 100%;
    height: 400px;
    /* background-color: red; */
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgb(213, 217, 213);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; /* Adding a smooth transition effect */
}

.ListingItemCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adding a more pronounced shadow on hover */
}
.ListingImgFull{
    height: 100%;
    width: 100%;
    /* background-color: red; */
    overflow: hidden;
    /* padding: 20px; */
}
.ListingDisplayCover img {
    width: 100%;
    height: 100%;
   
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* background-color: red;
    padding: 20px; */
}

.ListingDetailsSec {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    /* background-color: red; */
}

.ListingStore {
    padding: 10px;
}

.ListingName,
.ListingPrice {
    padding: 5px;
}

@media screen and (max-width: 730px){   


    .carouselCover{
        position: relative;
        padding-left: 10px;
        /* background-color: green; */
      }

      .carouselCover ul {
        display: flex;
        list-style: none;
        height: 360px;
        overflow-x: scroll;
        padding: 20px 0;
        flex: 0 0 360px;
        margin: 0 auto;
    
        /* background-color: red; */
      }

        
    .carouselCover li {
        flex: 0 0 270px;
        /* background: var(--accent); */
        margin: 0 20px 0 0;
        user-select: none;
        /* background-color: aqua; */
        overflow: hidden;
        cursor: pointer;
    }

    .ListingItemCard{
        height: 300px;
    }

}