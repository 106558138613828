
*{ 
 
    box-sizing: border-box;
    position: relative;
   

}

.wholeNav{ 
    
    display: flex;
    flex-direction: column;

}
.responseNav{ 
    
  position: relative;
  margin-bottom: 10px;

}
.Map{ 
    position: relative;
    height: 340px; 
    background-color: black; 
    color: black; 
    margin-top: 20px;
} 

.Map .mapConatainer{ 
 
    height: 100%;
    width: 100%;
}

.contDetails{ 
 
    width: 100%; 
    height: 400px;
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
    font-family: 'Josefin Sans', sans-serif; 
    color: #4C4C4C;
    align-items: center;
    padding-left: 25px;


} 

.contDetails h1{ 
 
    color: #4C4C4C;

}