
.BColumns{ 
    
    position: relative;
    font-family: 'Josefin Sans', sans-serif; 
    background-color: #F8F6F5;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    column-gap: 30px;
    width: 100%;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    /* z-index: -1; */
}

 /* .rightColumns{

    position: relative;
    background-color: white;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    height: 432px;


}  */

.instaPost{  
    position: relative;
    background-color: white;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 30px;
    height: 164px;
    cursor: pointer;
    /* background-color: red; */
    z-index: 99;
}

.recentPost{ 
    position: relative;
    background-color: white; 
    min-width: 250px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 30px;
    height: 432px;
    /* background-color: red; */
    z-index: 99;

}
.BHeading{ 
    cursor: pointer;

}


.leftColumns { 
    
    background-color: white;
    width: 100%;
    object-fit: cover;
    /* max-width: 700px; */
    min-width: 400px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
} 

.leftColumns p{ 
    
    font-family: 'Josefin Sans', sans-serif;  
    font-size: 16px;
    line-height: 25px;
}

.leftColumns img{ 

    width: 100%;
    min-height: 500px;
    object-fit: cover;
}

.NextPrev{ 
    display: flex;
    width: 100%; 
    justify-content: space-between; 
    margin-top: 30px;
    padding-left: 20px;
    margin-bottom: 30px;
} 

.NextPrev img{  
    
    width: 100%; 
    object-fit: cover; 
    max-width: 150px; 
    min-height: 100px;
} 

.NextPrev{ 
    display: flex;
}  




.prevPost{ 

    display: flex;
}  

.nextPost{ 

    display: flex;
} 

.prevPText{ 

    display: flex;
    flex-direction: column;
} 

.nextPText{  

    display: flex;
    flex-direction: column;

}


/* .blogIcons{  
    margin-top: 20px;
    margin-bottom: 20px;
   
    font-size: 25px;

}
.blogIcons i{  
    margin-right: 5px;

} 



  .blogIcons .fab.fa-facebook{  
    color: rgb(22, 94, 239);

}  

.blogIcons .fab.fa-twitter {  
    color: rgb(28, 135, 236);

}  


.blogIcons .far.fa-envelope{  
    color: rgb(7, 73, 255);

} 

.blogIcons .fab.fa-whatsapp{  
    color: rgb(27, 165, 12);

}  

.blogIcons .fab.fa-linkedin {  
    color: rgb(13, 102, 166);

}  

.blogIcons .fas.fa-plus-circle {  
    color: rgb(7, 73, 255);

}  */




/* Ressponsive design */ 

@media (max-width: 800px) { 


    .BColumns{ 
    
        font-family: 'Josefin Sans', sans-serif; 
        background-color: #F8F6F5;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 30px;
        width: 100%;
        padding-top: 50px;
        padding-left: 0px;
        padding-right: 0px;
    } 

    .recentPost{ 

        background-color: white; 
        min-width: 200px;
        width: 100%;
        padding-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 30px;
        height: 432px;
    
    }

    .leftColumns { 
    
        background-color: white;
        width: 100%;
        object-fit: cover;
        /* max-width: 700px; */
        min-width: 400px;
        padding-top: 50px;
        padding-left: 30px;
        padding-right: 50px;
    } 
    

   
  
     
  }