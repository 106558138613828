

/* .ProductWordingCover {
    display: flex; 
    background-color: #f8f8f8;
    padding: 20px; 
  }
  
  .imagePart {
    flex: 1; 
    margin-right: 20px;
  }
  
  img {
    max-width: 100%; 
    height: auto;
    display: block; 
  }
  
  .wordPart {
    flex: 1; 
  }
  
  h1 {
    font-size: 2em; 
    margin-bottom: 10px; 
  }
  
  p {
    font-size: 1.2em; 
    line-height: 1.5; 
    margin-bottom: 15px;
  }
  
  button {
    background-color: #4285f4; 
    color: white; 
    padding: 10px 20px; 
    font-size: 1em; 
    border: none; 
    cursor: pointer;
  }
  
  button:hover {
    background-color: #3367d6; 
  }
   */


   .ProductWordingContainer{
    background-color: white;
    padding: 50px;
   }


   .ProductWordingCover{
     display: flex;
     flex-wrap: wrap;
     height: 300px;
     column-gap: 30px;
     background-color: #000;
     color: white;
     overflow: hidden;
     border-radius: 10px;

   }
   .ProductWordingCover img{
       object-fit: cover;
   }

   .imagePart {
    flex: 1; 
    /* margin-right: 20px; */
    overflow: hidden;
    height: 100%;
    width: 100%;
    
  }
   .imagePart img{

       height: 100%;
       width: 100%;
       object-fit: cover;
       background-position: center;

   
    
  }
  
  /* img {
    max-width: 100%; 
    height: auto;
    display: block; 
  } */
  
  .wordPart {
    flex: 1; 
    min-width: 400px;
   
    padding: 30px;
  }
  .wordPart p {
    margin-top: 3px;
      font-size: 1.1rem;
  }
  
  .wordPart button{

     border: none;
     margin-top: 20px;
     padding: 13px;
     width: 200px;
     cursor: pointer;
     font-weight: 800;
   
  }
  

  /* @media screen (max-width: 730px) { */

    @media screen and (max-width: 730px){   

        .ProductWordingContainer{
            background-color: white;
            padding: 20px;
           }
        

        .ProductWordingCover{
            display: flex;
            flex-wrap: wrap;
            height: auto;
            column-gap: 30px;
            background-color: #000;
            color: white;
            overflow: hidden;
            border-radius: 10px;
       
          }

          .wordPart {
               text-align: center;
               min-width: 200px;
               font-size: 1rem;
               /* color: yellow; */
               /* padding: ; */
          }
          .wordPart p{
               text-align: center;
               font-size: .9rem;
               
               /* padding: ; */
          }
          


    .imagePart {
        flex: 1; 
        
        /* margin-right: 20px; */
        overflow: hidden;
        min-height: auto;
        max-height: 250px;
        min-width: 200px;
    
        
      }

     
    
  }