*{ 
 
    box-sizing: border-box;
    padding: 0;
    margin: 0;

}  

.section{ 
    padding-left:  50px;
    padding-right: 20px;
}


.Heading11{
    position: relative;
    display: flex;
   justify-content: space-around; 
   font-family: 'Josefin Sans', sans-serif;  
   letter-spacing: 3.77px; 
   margin-top: 30px;
   z-index: 2;
  

}

.Heading11 h3{
    position: relative;
    font-size: 29px; 
    line-height: 37px;
    font-weight: 700;
    color: #4C4C4C;
}
.backContent h2{

    position:absolute;
    font-family: 'Josefin Sans', sans-serif; 
    font-size: 70px;
    color: rgb(219, 219, 219);
    margin-left: 50px;
    
    font-weight: 700;
    letter-spacing: 2px;
    z-index: 1;
}

.SectionCards{ 
    position: relative;
    display: grid;
    /* height: 100vh; */
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr)); 
    column-gap: 30px;
    /* font-family: 'Josefin Sans', sans-serif;   */
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

}

.SectionCards p{ 

    font-family: 'Josefin Sans', sans-serif; 
    font-size: 23px;
    line-height: 54.5px;
    color: #888888;
    z-index: 2;
    
}

.SectionCards h3{ 

    font-family: 'Josefin Sans', sans-serif; 
    font-size: 18px;
    text-align: left;
    letter-spacing: 2px;
    line-height: 3rem;
    z-index: 2;
    
}

.SectionImg {
    overflow: hidden;
}

.SectionImg img{ 
     
    position: relative;
     width: 100%;
     height: 100%;
     /* min-height: 350px; */
     object-fit: cover;
     overflow: hidden;
}
.writeUp{ 
     
    
    position: relative; 
    display: flex;
    flex-direction: column; 
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #F8F6F5;
    padding-top: 50px;
    
}

.writeUp h3{ 
  
    text-align: center;
    
}

@media screen and (max-width: 600px){  

    .section{ 
        padding-left:  10px;
        padding-right: 5px;
    }
    
  
    
  
  }