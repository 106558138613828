
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.SubHeadingWork{ 

    text-align: center; 
    font-family: 'Josefin Sans', sans-serif;
    color: #4C4C4C; 
    /* border-bottom: 1px solid #888888 ; */
    font-size: 20px;
    /* line-height: 40px;   */
    padding-top: 3px;
    width: 100%; 
    overflow:hidden ;
    overflow: hidden; 
    margin-bottom: 3px;
    
    padding-left: 2rem;
    padding-right: 2rem;
    /* background-color: red; */

    /* margin-left: 50px;
    margin-right: 100px; */
    
 }

.WGallery{
    
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
    column-gap: 5px;
    /* grid-auto-rows: 350px;  */
    /* grid-auto-flow: dense; */
    /* border-top: 0.5px solid #4C4C4C; ; */


} 
/* .WGallery .Cover:nth-child(7n+1){

   
    grid-column: span 2; 
    background-color: red;
    grid-row: span 2;

} */


.Cover{ 

    /* display: flex; 
    width: 300px; */ 
    /* margin-bottom: 30px; */
    margin-top: 30px;
    font-family: 'Josefin Sans', sans-serif;
    color: #4c4c4c; 
    
} 

.coupleWrap{ 

    display: flex; 
    /* width: 300px; */
    min-height: 300px;
} 

.VertText {  
    position: relative;
    text-align:center; 
    padding-top: 50px;
   
}

.VertText h3{  
    
   
     bottom: 0;
     font-family: 'Josefin Sans', sans-serif;
     color: #4c4c4c;
     writing-mode: vertical-lr;
     transform: rotate(180deg);
     text-align: justify;
     letter-spacing: 2px;
   
 

}



.coupleWrap img{ 

    width: 100%;  
    min-height: 500px;
    max-height: 500px;
    min-width: 400px;
    object-fit: cover; 
} 

.GBride h3{ 
 
    font-size: 20px; 
    line-height: 27px;
    padding-top: 5px;
   
}