

.ListingHeadingCover{

    /* padding-left: 30px; */
    padding-bottom: 30px;
    width: 100%;
    text-align: left;
    color: rgb(58, 140, 140);

    

}

.ListingContainer {
    position: relative;
    padding: 30px;
}

.ListingDisplayCover {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 20px;
    /* background-color: red; */
}

/* .ListingItemCard{
    text-align: center;
} */
.ListingItemCard .ListingName p{
    text-align: right;
    /* background-color: red; */
}

.ListingImgListingImgSec{
    height: 80%;
    object-fit: cover;
}

.ListingItemCard {
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgb(213, 217, 213);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; /* Adding a smooth transition effect */
}

.ListingItemCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adding a more pronounced shadow on hover */
}

.ListingDisplayCover img { 
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ListingDetails p{
    padding: 0;
    margin: 0;
}
.ListingDetails {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

  
}
.ListingDetails p {
  padding: 0;
  margin: 0;
}

.ListingStore {
    padding: 10px;
}
.Radar {
    padding: 0 10px;
    
}
.Radar p{
    padding: 0;
    margin: 0;
    font-size: 10px;
}

.ListingName,
.ListingPrice {
    padding: 0;
    font-size: 14px;
}

.radarFontIcon{
    margin-right: 5px;
   
}

/* .ListingContainer {
    position: relative;
    padding: 30px;
    overflow: hidden; 
}

.ListingDisplayCover {
    width: 100%;
    display: flex;
    transition: transform 0.3s ease; 
}

.ListingItemCard {
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid rgb(213, 217, 213);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; 
}

.ListingItemCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ListingDisplayCover img {
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ListingDetails {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.ListingStore {
    padding: 10px;
}

.ListingName,
.ListingPrice {
    padding: 5px;
}


@media (max-width: 768px) {
    .ListingDisplayCover {
        transform: translateX(0);
    }

    .ListingItemCard {
        width: calc(100% / 3); 
    }
} */



/* 
.ListingContainer{
    position: relative;
    padding: 30px;
}

.ListingDisplayCover{
    width: 100%;
    display: grid;
   
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); 
    gap: 20px;

}

.ListingItemCard{
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}
.ListingDisplayCover img{

    width: 100%;
    object-fit: cover;
    overflow: hidden;
 
}

.ListingDetails{
    display: flex;
    justify-content: space-between;
} */