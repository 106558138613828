
*{

     box-sizing: border-box;
     margin :0; 
     padding: 0;

}


.QuoteOver{
    position: relative;
    /* height: 300px; */
    display: flex; 
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    text-align: center;
    padding: 0 30px;
    /* background-color: yellow; */
    /* width: 100vh; */
} 

.Quote{ 
     position: relative;
     /* width: 100%;  */
     /* height: 40vh; */
     /* display: flex;
     flex-direction: column; */
     /* align-items:center;  */
     /* margin-top: 0;
     margin-bottom: 0;    */
     /* justify-content:space-around; */
     font-family: 'Josefin Sans', sans-serif; 
     font-size: 23px;
     font-weight: 300;
     line-height: 40.25px;
     padding-top: 30px;
     padding-bottom: 30px;
     color: #888888;
     /* text-align: center; */


}

.Quote h3{ 

    font-size: 17px;
    font-weight: 700;
    letter-spacing: 3px; 
    line-height: 22.1px;
} 


.QuoteOver .rightarrowQ h3{
    position: absolute;
    top: 0;
    right: 150px;
    font-family: 'Josefin Sans', sans-serif; 
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 40.5px;
    border-left: solid 1px #888888;
    background-color: red;
    /* padding-left: 7px; */
    /* color: #ffffff; */ 
    color: #4c4c4c;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
 .QuoteOver .leftarrowQ h3{

    position: absolute;
    top: 0;
    left: 32px;
    left: 150px;
    font-family: 'Josefin Sans', sans-serif; 
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 40.5px;
    border-right:  solid 1px #888888;
    background-color: red;

   color: #4c4c4c;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    
  }


  @media screen and (max-width: 600px){  

   

    .QuoteOver .right-arrow h3{
        position: relative;
        top: 40%;
        /* right: 150px; */
        font-family: 'Josefin Sans', sans-serif; 
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 3px;
        line-height: 40.5px;
        border-left: solid 1px #888888;
        /* color: #ffffff; */ 
        color: #4c4c4c;
        z-index: 10;
        cursor: pointer;
        user-select: none;
        /* background-color: red; */
      }

      .QuoteOver .left-arrow h3{
        position: relative;
        top: 40%;
        /* left: 32px;
        left: 150px; */
        font-family: 'Josefin Sans', sans-serif; 
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 3px;
        line-height: 40.5px;
        border-right:  solid 1px #888888;
        /* background-color: red; */
    
        /* color: #ffffff; */
       color: #4c4c4c;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }
    

    
  
    
    
  
  }
  
  