 
 *{
    box-sizing: border-box;
    /* background-color: red; */
 }

 .operationsContainer{
    position: relative;
    min-height: 200px;
    /* background-color: green; */
    padding: 30px;
    /* background-color: red; */
   

 }

 .ScheduleCover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Add a cool box shadow */
    display: flex;
    justify-content: space-between;
    flex: 1;
    /* background-color: red; */
    /* align-items: center; */
 }

 .scheduleCoverPart{
    
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);  */
    min-height: 150px;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding: 30px;

 }
 .scheduleCoverPart p{
    
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);  */
   padding: 0;
   margin: 0;

 }

 .ScheduleCover .schHeading p{ 
    font-size: 1.2rem;
    color: blue;
    margin: 0;
 
 } 

 @media screen and (max-width: 730px){   

    .ScheduleCover{
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Add a cool box shadow */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        /* background-color: red; */
        /* align-items: center; */
     }
  

    
    
}

